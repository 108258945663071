.container {
    overflow: hidden;
    position: relative;
  }
  
  .slider {
    width: 100%;
    height: 110vh;
  
    display: flex;
    flex-direction: row;

    
  }
  
  .slide {
    min-width: 100%;
    height: 100%;
  }
  
  .swipper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .dot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    bottom: 20px
  }
  
  .dot {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 5px;
    opacity: 0.8;
  }
  
  .dot:not(:last-child) {
    margin-right: 10px;
  }